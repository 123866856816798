import "./App.css";
import Error from "./pages/Error/Error";
import { useState, useEffect, lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { useAuth } from "./context/AuthContext";
import Cart from "./pages/Cart";
import Loading from "./components/Loading";
import ErrorBoundary from "./components/ErrorBoundry";
import OnlineOfflineToast from "./components/Internetstatus";
import { useNavigate } from "react-router-dom";
import { CookieStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";

const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/Login/Login"));
const Signup = lazy(() => import("./pages/Login/Signup"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Profile = lazy(() => import("./pages/Profile"));
const PrivacyPolicy = lazy(() => import("./pages/Privacypolicy"));
const TermsCondition = lazy(() => import("./pages/Termsandcondition"));
const CancellationPolicy = lazy(() => import("./pages/Refundpolicy"));
const ErrorPage = lazy(() => import("./pages/Error/Error"));
const EdgePrelims = lazy(() => import("./components/EdgePrelims"));
const PreliminaryExamination = lazy(() =>
  import("./components/PreliminaryExamination")
);
const ScoreBoard = lazy(() => import("./components/UpscAnswer"));
const PrelimsMockTest = lazy(() => import("./pages/Quiz/PrelimsMockTest.js"));
const PrelimsMockTestHistory = lazy(() =>
  import("./pages/Quiz/PrelimsMockTestHistory")
);
const MocktestHistory = lazy(() => import("./pages/Quiz/MocktestHistory.js"));
const MockTestInstructions = lazy(() => import("./pages/Quiz/Instructions.js"));
const MockTestSubjectHistory = lazy(() =>
  import("./pages/Quiz/TestSubjectHistory.js")
);

function App() {
  const [sectionname, setSectionName] = useState("");
  const { state } = useAuth();
  const [scrolling, setScrolling] = useState(false);
  const userPoolWebClientId = process.env.REACT_APP_AWS_USERPOOL_APP_CLIENTID;
  const clienid = process.env.REACT_APP_AWS_USERPOOLID;

  const commonPaths = [
    "/",
    "/home",
    "/products",
    "/features",
    "/about",
    "/contact",
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // amp configure
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolClientId: userPoolWebClientId,
        userPoolId: clienid,
        authenticationFlowType: "USER_PASSWORD_AUTH",
        // authenticationFlowType: "CUSTOM_CHALLENGE",
      },
    },
  });

  // set the userCredentials in cookies
  cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());

  const scrollToSection = (sectionId) => {
    console.log("sectionId", sectionId);
    setSectionName(sectionId);
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const RedirectToHome = () => {
    const navigate = useNavigate();

    useEffect(() => {
      if (state.isLoggedIn) {
        navigate("/chat", { replace: true });
      } else {
        navigate("/", { replace: true });
        // navigate("/edgeprelims2024", { replace: true });
      }
    }, [navigate]);

    return null;
  };

  const RedirectToDashboard = () => {
    const navigatetoHome = useNavigate();
    useEffect(() => {
      if (state.isLoggedIn) {
        navigatetoHome("/chat", { replace: true });
      }
    }, [navigatetoHome]);
  };

  console.log("isLoggedIn", state?.isLoggedIn);

  if (state.isLoggedIn) {
    return (
      <>
        <OnlineOfflineToast />
        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<Loading />}>
                <RedirectToHome />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={<Loading />}>
                <RedirectToDashboard />
              </Suspense>
            }
          />
          {commonPaths.map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <Suspense fallback={<Loading />}>
                  <Home sectionId={sectionname} />
                </Suspense>
              }
            />
          ))}
          <Route
            path="/chat"
            element={
              <Suspense fallback={<Loading />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            path="/profile"
            element={
              <Suspense fallback={<Loading />}>
                <Profile />
              </Suspense>
            }
          />
          <Route
            path="/cart"
            element={
              <Suspense fallback={<Loading />}>
                <Cart />
              </Suspense>
            }
          />
          <Route
            path="/cart/:pricingplan"
            element={
              <Suspense fallback={<Loading />}>
                <Cart />
              </Suspense>
            }
          />
          <Route
            path="preliminary-mock-test"
            element={
              <Suspense fallback={<Loading />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            path="preliminary-mock-test-history"
            element={
              <Suspense fallback={<Loading />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            path="preliminary-mock-test/instructions"
            element={
              <Suspense fallback={<Loading />}>
                <MockTestInstructions />
              </Suspense>
            }
          />
          <Route
            path="subject-and-test-history"
            element={
              <Suspense fallback={<Loading />}>
                <MockTestSubjectHistory />
              </Suspense>
            }
          />
          <Route
            path="prelims-mock-test"
            element={
              <Suspense fallback={<Loading />}>
                <PrelimsMockTest />
              </Suspense>
            }
          />
          <Route
            path="prelims-mock-test/history"
            element={
              <Suspense fallback={<Loading />}>
                <PrelimsMockTestHistory />
              </Suspense>
            }
          />
        </Routes>
      </>
    );
  } else {
    return (
      <div className={`app ${scrolling ? "scrolling" : ""}`}>
        <OnlineOfflineToast />
        <div className="sidebar-container">
          <Routes>
            <Route
              path="*"
              element={
                <Suspense fallback={<Loading />}>
                  {/* <ErrorPage scrollToSection={scrollToSection} /> */}
                  <RedirectToHome />
                </Suspense>
              }
            />
            {/* <Route
              path="/"
              element={
                <Suspense fallback={<Loading />}>
                  <RedirectToHome />
                </Suspense>
              }
            /> */}
            <Route
              path="/"
              element={
                <Suspense fallback={<Loading />}>
                  <Home />
                </Suspense>
              }
            />

            {commonPaths.map((path) => (
              <Route
                key={path}
                path={path}
                element={
                  <Suspense fallback={<Loading />}>
                    <Home sectionId={sectionname} />
                  </Suspense>
                }
              />
            ))}
            <Route
              path="/sign-in"
              element={
                <Suspense fallback={<Loading />}>
                  <Login />
                </Suspense>
              }
            />
            <Route
              path="/edgeprelims2024"
              element={
                <Suspense fallback={<Loading />}>
                  <EdgePrelims />
                </Suspense>
              }
            />
            <Route
              path="/preliminaryexamination"
              element={
                <Suspense fallback={<Loading />}>
                  <PreliminaryExamination />
                </Suspense>
              }
            />
            <Route
              path="/scoreboard"
              element={
                <Suspense fallback={<Loading />}>
                  <ScoreBoard />
                </Suspense>
              }
            />

            <Route
              path="/sign-up"
              element={
                <Suspense fallback={<Loading />}>
                  <Signup />
                </Suspense>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <Suspense fallback={<Loading />}>
                  <PrivacyPolicy />
                </Suspense>
              }
            />
            <Route
              path="/terms-and-conditions"
              element={
                <Suspense fallback={<Loading />}>
                  <TermsCondition />
                </Suspense>
              }
            />
            <Route
              path="/cancellation-policy"
              element={
                <Suspense fallback={<Loading />}>
                  <CancellationPolicy />
                </Suspense>
              }
            />
          </Routes>
        </div>
      </div>
    );
  }
}

export default App;
